.map-container {
  height: 100%;
  width: 100%;
  /* height: 100%;
  width: 50%; */
  /* width: max-content; */
  /* position: absolute; */
}

.calculation-box {
  height: 75px;
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  background: rgba(255, 255, 255, 0.8);
  padding: 15px;
  text-align: center;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  z-index: 2;
}