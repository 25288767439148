.mapboxgl-popup-content-3D {
    position: relative;
    background: #4a4a4a;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    pointer-events: auto;
}

.mapboxgl-popup-content.deal-desc{
	box-shadow:none;
	max-height: 80px;
    overflow-y: auto;
	background: transparent;
	padding: 5px 15px 5px 5px;

}

.mapboxgl-popup-content{
	border-radius: 6%/10%;
    padding: 0;   
    overflow-y: hidden;
}

.marker {
    max-width: 240px;
    display: flex;
    margin-bottom: 5em;
    text-align: center;
    color: black;
}

.popup {
    font-weight: 500;
    color: white !important;
    padding: 5px;
}



.mapboxgl-popup-content-3D {
    padding: 5px;
}


.mapboxgl-popup-tip-3D{
	border-top-color: #4a4a4a;
	align-self: center;
    border-bottom: none;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip-3D{
	border: 10px solid transparent;
    border-top-color: #4a4a4a;
}


.mapboxgl-popup-close-button-3D{
	color: white !important;
}


.mapboxgl-popup-tip-3D{
	width: 0;
    height: 0;
    border: 10px solid transparent;
    z-index: 1;
	border-top-color: #4a4a4a;
	align-self: center;
    border-bottom: none;
}
.label3D .mapboxgl-popup-close-button{
    color: white;
}