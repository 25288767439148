#btn-3D.disable {
	cursor: grab;
}

#btn-3D-sec.disable {
	cursor: grab;
}

.mapboxgl-ctrl-pitchtoggle-3d {
	background-image: url("./mapIcons/3D.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.mapboxgl-ctrl-pitchtoggle-2d {
	background-image: url("./mapIcons/2D.svg");
	background-position: center;
	background-repeat: no-repeat;
}
.mapboxgl-ctrl-style-toggle-satellite {
	background-image: url("./mapIcons/satellite.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.mapboxgl-ctrl-style-toggle-regular {
	background-image: url("./mapIcons/map.svg");
	background-position: center;
	background-repeat: no-repeat;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon{
	background-image: url("./mapIcons/arrows-minimize.svg");
	background-position: center;
	background-repeat: no-repeat;
}
.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line{
	background-image: url("./mapIcons/ruler-horizontal.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon{
	background-image: url("./mapIcons/ruler-combined.svg");
	background-position: center;
	background-repeat: no-repeat;
}


.mapboxgl-ctrl-MinimizeBtn{
	background-image: url('https://v2.snap.land/vectors/map.svg');
}

.clearButton {
	background-image: url("./mapIcons/power-off.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.mapHomeButton {
	background-image: url("./mapIcons/house.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon{
	background-image: url("./mapIcons/plus.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon{
	background-image: url("./mapIcons/minus.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon{
	background-image: url("./mapIcons/arrows-maximize.svg");
	background-position: center;
	background-repeat: no-repeat;
}


.listClass {
	background: rgba(255, 255, 255, 0.8);
	border-radius: 0.357rem;
	margin-top: 0.5rem;
}

.hover-li :hover {
	/* background: rgb(187, 187, 187); */
}

#mapSearchList::-webkit-scrollbar-track {
	border: 1px solid rgb(100, 100, 100);
	padding: 2px 0;
	border-radius: 10px;
	background-color: #fffeff;
}

#mapSearchList::-webkit-scrollbar {
	width: 10px;
}

#mapSearchList::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	background-color: #3999db;
	border: 1px solid #000;
}

input {
	outline: none;
}

.open-search-input{
	width: 240px !important;
	background: #fffeff !important;
	padding-left: 10px !important;
	color: #000 !important;
	cursor: auto !important;
}
#userInputAddress::-webkit-search-cancel-button {
	-webkit-appearance: searchfield-cancel-button;
}

#userInputAddress {
	background: #fffeff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
	text-align: center;
	box-shadow: none;
	border: none;
	border-radius: 1rem 0% 0% 1rem;
	padding: 3px 15px 3px 32px;
	width: 240px;
	-webkit-transition: width .5s;
	-moz-transition: width .5s;
	transition: width .5s;
}

input:-moz-placeholder {
	color: #999;
}

input::-webkit-input-placeholder {
	color: #999;
}


/* Demo 2 */

#searchDiv #userInputAddress {
	width: 15px;
	padding-left: 10px;
	/* color: transparent; */
	cursor: pointer;
}

#searchDiv #userInputAddress:hover {
	background-color: #fff;
}


#searchDiv input:-moz-placeholder {
	color: transparent;
}

#searchDiv input::-webkit-input-placeholder {
	color: transparent;
}
.listSpanContent{
	cursor: pointer;
    padding: 5px 0px 5px 0;
    list-style-type: none;
    text-align: right;
}

#citydropdownMap{
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat;
	-webkit-appearance:none;
	padding-right: 0.5rem ;
	padding-left: 1.5rem;
	height: 100%;
	border: none;
	border-left:solid 1px #ccc; ;
	border-radius:  0% 1rem 1rem 0%;
}
#searchBar{
	border-radius: 1rem;
	display: flex;
	background: #fff;
	z-index: 3;
	position: absolute;
	direction: rtl;
}

#mapSearchList{
	z-index: 3;
    position: absolute;
    top: 4rem;
    width: 265px;
    padding-right: 0%;
    padding-left: 0%;
    max-height: 155px;
    overflow: auto !important;
    border-radius: 1rem;
}

.blink {
	animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
	50% {
	  opacity: 0;
	}
  }

li.nav-item.nav-search > div > div.ui-autocomplete-input.choosecityinput > select{
	margin-top: 15%;	
}

.yoad{
    position: absolute;
}