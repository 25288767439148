#legend {
	position: absolute;
	right: 0;
	padding: 10px 0 10px 10px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	line-height: 18px;
	margin-bottom: 40px;
	max-width: 33%;
	text-overflow: ellipsis;
	font-family: 'Open Sans', sans-serif;
	max-height: 50%;
	overflow: auto;
}

.legend-key {
	display: inline-block;
	border-radius: 20%;
	border: 1px solid black;
	width: 10px;
	height: 10px;
	margin-left: 5px;
}

.legend-key-tab {
	display: inline-block;
	border-radius: 20%;
	border: 1px solid black;
	width: 10px;
	height: 10px;
	margin-left: 5px;
	margin-right: 8px;
}

.map-overlay {
	text-align: initial;
	position: absolute;
	bottom: 0;
	background: rgba(255, 255, 255, 0.8);
	margin-right: 20px;
	font-family: Arial, sans-serif;
	z-index: 1;
	direction: rtl;
	right:0;
	border-radius: 3px;
}

.symbol_layer{
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    width: 22px;
    height: 26px;
    margin-right: 4px;
}
.leg_symbol_div{
	display: flex;
    align-items: center;
}

.underline_text{
    text-decoration: underline;
}

.snap-m-r-10{
    margin-right: 10px;
}

.line_tab{
    margin-left: 8px;
    margin-right: 2px;
    border: none;
    width: 5px;
    height: 12px;
    display: inline-block;
    transform: rotate(45deg);
}
.line_tab_inner{
    margin-right: 8px;
}