.grid {
    display: grid;
    height: 100vh;
}

.grid-2-cells {
    grid-template-columns: 1fr 10px 1fr;
}

.grid-4-cells {
    grid-template-rows: 1fr 10px 1fr;
}

.gutter-col {
    grid-row: 1/-1;
    cursor: col-resize;
    background-image: url('https://split.js.org/vertical.png');
    background-color: #ca4791;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter-col-1 {
    grid-column: 2;
    z-index: 2;
}

/* .yoad{
    height: 100vh;
} */

.gutter-row {
    grid-column: 1/-1;
    cursor: row-resize;
    background-image: url('https://split.js.org/horizontal.png');
    background-color: #ca4791;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter-row-1 {
    grid-row: 2;
    z-index: 2;
}


.parent-div{
    display: contents;
}